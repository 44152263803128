import { authApi } from "./axios";

export const getEssay = async (essayId: number) => {
  const result = await authApi.get(`/api/essay?essayId=${essayId}`);
  return result;
};

export const getEssayListByPos = (researchId: number) => {
  return authApi.get(`/api/essay/downloads/pos/${researchId}`);
};

export const getEssayListByPsyPos = (researchId: number) => {
  return authApi.get(`/api/essay/downloads/psypos/${researchId}`);
};

export const addEssay = (newEssay: any, researchId: number) => {
  return authApi.post(`/api/essay/${researchId}`, newEssay);
};

export const getEssayResultByPos = (essayId: number) => {
  return authApi.get(`/api/essay/pos/${essayId}`);
};

export const getEssayResultByPsyPos = (essayId: number) => {
  return authApi.get(`/api/essay/psyPos/${essayId}`);
};