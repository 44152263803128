import store from 'storejs';
import {useSetRecoilState} from 'recoil';

import {login} from '../../service/login';
import {IUser} from '../../types/user/index.d';
import {authApi} from '../../service/axios';
import {isLoggedInState} from '../../recoil/atoms';
import {IHeadersDefaults} from '../../types/axios/index.d';
import {CredentialResponse} from "@react-oauth/google";
import jwtDecode from "jwt-decode";

const useLogin = () => {

  const setLoggedInState = useSetRecoilState(isLoggedInState);

  return ({credential}: CredentialResponse) => {
      if (!credential) return;
      const userInfo: any = jwtDecode(credential, {});
      const loginInfo: IUser = {
          email: userInfo.email,
          name: userInfo.name,
          accessToken: credential,
          isLoggedIn: true,
          profile: userInfo.picture,
      };
      login(loginInfo)
          .then((response) => {
              store.set(String(process.env.REACT_APP_LOCAL_USER_KEY), {
                  ...loginInfo,
                  accessToken: response.data.accessToken
              });
              authApi.defaults.headers = {accessToken: `Bearer ${response.data.accessToken}`} as IHeadersDefaults;
              setLoggedInState(loginInfo);
          })
          .catch(() => { // login Error
              localStorage.clear();
              store.clear();
          });
  };
};

export default useLogin;