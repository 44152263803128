import cs from './login.module.scss';
import useLogin from '../../hooks/user/useLogin';
import {GoogleOAuthProvider, GoogleLogin} from "@react-oauth/google";

const Login = () => {

    const onSuccessHandler = useLogin();

    return(
    <section className={cs.loginSection}>
            <title>Psychological-Language-Analysis-System.</title>
            <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_LOGIN_KEY)}>
                <GoogleLogin
                    onSuccess={onSuccessHandler}
                    onError={console.log}
                />
            </GoogleOAuthProvider>
        </section>
    );
};

export default Login;