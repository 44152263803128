import cs from './research.module.scss';
import dayjs from 'dayjs';
import { useState } from 'react';
import {useMount} from 'react-use';
import { useRecoilState } from 'recoil';

import Header from "../../components/common/header";
import Button from '../../components/common/button';
import useLogOut from '../../hooks/user/useLogOut';
import ResearchModal from '../../components/research/modal';
import { researchState } from '../../recoil/atoms';
import { getResearchList } from '../../service/research';
import { useNavigate } from "react-router-dom";

const Research = () => {

  const [{ researchList, totalElements, totalPages, currentPage }, setResearchInfo] = useRecoilState(researchState);
  const [showModal, setShowModal] = useState(false);

  const onLogout = useLogOut();
  const nav = useNavigate();

  const onMoreHandler = (curPage: number) => {
    getResearchList(curPage)
    .then((res) => {
      setResearchInfo(prev => {
        return {
          ...prev,
          currentPage: prev.currentPage + 1,
          totalElements: res.data.totalElements,
          totalPages: res.data.totalPages,
          researchList: curPage !== 0 ? [...prev.researchList, ...res.data.content] : res.data.content
        };
      });
    })
    .catch( /* () => onLogout() */ );
  };

  const onShowModal = () => setShowModal(true);

  useMount(() => {
    getResearchList(0)
      .then((res) => {
        setResearchInfo(prev => {
          return {
            ...prev,
            currentPage: 1,
            totalElements: res.data.totalElements,
            totalPages: res.data.totalPages,
            researchList: res.data.content
          };
        });
      })
      .catch( /* () => onLogout() */ );
  });

  const onResearchCikck = (researchId: number) => {
    nav(`${researchId}`);
  };

  return(
    <section>
      <header><Header /></header>
      <main>
        <section className={cs.titleSection}>
          <title className={cs.title}>연구목록</title>
        </section>
        <div className={cs.postInfo}>
          <p>{`📄총 ${totalElements}건`}</p>
          <button type='button' onClick={onShowModal}>+ 연구개설</button>
        </div>
        <section className={cs.itemListSection}>
          <table>
            <thead>
              <tr>
                <th>
                  제목
                </th>
                <th>
                  시작일
                </th>
              </tr>
            </thead>
            <tbody>
              { researchList.map((research) => 
                <tr key={`research_key_${research.id}`} onClick={() => onResearchCikck(research.id)}>
                  <td>
                    {research.researchName}
                  </td>
                  <td>
                    { dayjs(`${research.researchStartDate[0]}-${research.researchStartDate[1]}-${research.researchStartDate[2]}`).format('YYYY-MM-DD')}
                  </td>
                </tr>)
              }
            </tbody>
          </table>
          { currentPage < totalPages && <Button text='더보기' onClickHandler={() => onMoreHandler(currentPage)} /> }
        </section>
      </main>
      { showModal && <ResearchModal setShowModal={setShowModal} /> }
    </section>
  );
};

export default Research;