import { basicApi } from "./axios";
import { ILoginUser } from "../types/user/index.d";

export const login = async (data: ILoginUser) => {
  const result = await basicApi.post('/api/account', {
      email: data.email,
      name: data.name,
      code: '', // TODO
  }, {
    headers: {
      accessToken: data.accessToken
    }
  });
  return result;
};