import store from 'storejs';
import axios, { AxiosRequestConfig } from "axios";


const BASE_URL = String(process.env.REACT_APP_API_BASE_URL);
const BASE_URL_AUTH = String(process.env.REACT_APP_AUTH_BASE_URL);

const axiosApi = (url: string, options?: AxiosRequestConfig) => axios.create({ baseURL: url, ...options });

const axiosAuthApi = (url: string, option?: AxiosRequestConfig) => {
  const { accessToken } = store.get(String(process.env.REACT_APP_LOCAL_USER_KEY)) || '';
  /* console.log('accessToken : ', accessToken); */
  return axios.create({
      baseURL: url,
      headers: {
          accessToken: `Bearer ${accessToken}`,
      },
      ...option,
  });
};

/*
console.log('base url : ', BASE_URL);
console.log('base auth url : ', BASE_URL_AUTH);
*/ 

export const basicApi = axiosApi(BASE_URL);
export const authApi = axiosAuthApi(BASE_URL_AUTH);